// Scroll Bar
.hideScroll::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  margin-left: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-clip: content-box;
  border: 3px solid transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-clip: content-box;
  border: 3px solid transparent;
}

// Theme

::-webkit-scrollbar {
  // background-color: var(--bg-color-solid);
  background-color: transparent;
}
::-webkit-scrollbar-track {
  // background-color: var(--bg-color-solid);
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
}

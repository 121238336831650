// mat-table.responsive {
//   // Pantalla grande
//   @media (min-width: 600px) {
//     .mobile-label {
//       display: none;
//     }
//   }

//   // Pantalla pequeña
//   @media (max-width: 599px) {
//     .mobile-label {
//       min-width: 100px;
//       max-width: 45%;
//       display: inline-block;
//       font-weight: 500;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }

//     // Headers
//     mat-header-row:not(:has(> mat-header-cell.mat-sort-header)) {
//       display: none;
//     }

//     mat-header-row {
//       display: flex;
//       flex-wrap: wrap;
//       border-bottom: 0.5rem solid #303030;
//       padding: 8px;
//     }

//     mat-header-cell.mat-sort-header {
//       padding: 0;
//     }
//     mat-header-cell:not(.mat-sort-header) {
//       display: none;
//     }

//     // Rows
//     mat-row {
//       flex-direction: column;
//       align-items: start;
//       padding: 8px;
//       min-height: unset;
//       border-bottom: 0.5rem solid #303030;

//       .mat-column-acciones {
//         display: flex;
//         justify-content: center !important;
//         width: 100%;
//         padding: 0.5em 0 0;

//         button {
//           margin: 0 0.5em;
//         }
//       }
//     }

//     mat-cell {
//       min-height: unset;
//       width: 100% !important;
//       justify-content: space-between;
//       padding-left: 0 !important;

//       &:not(:last-child) {
//         border-bottom: 1px solid #6a6a6a;
//       }
//     }

//     mat-cell.hide {
//       display: none;
//     }
//   }
// }

// Material Table Responsive
mat-table.responsive {
  // Pantalla grande
  @media (min-width: 600px) {
    .mobile-label {
      display: none;
    }
  }

  // Pantalla pequeña
  @media (max-width: 599px) {
    .mobile-label {
      min-width: 100px;
      max-width: 45%;
      display: inline-block;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // Headers
    mat-header-row:not(:has(> mat-header-cell.mat-sort-header)) {
      display: none;
    }

    mat-header-row {
      display: flex;
      border-bottom: 0.5rem solid #303030;
      padding: 8px;
      overflow-x: auto;

      mat-header-cell {
        min-width: fit-content;
      }
    }

    mat-header-cell.mat-sort-header {
      padding: 0;
    }
    mat-header-cell:not(.mat-sort-header) {
      display: none;
    }

    // Rows
    mat-row {
      flex-direction: column;
      align-items: start;
      padding: 8px;
      min-height: unset;
      border-bottom: 0.5rem solid #303030;

      .mat-column-acciones,
      .mat-column-Acciones {
        display: flex;
        justify-content: center !important;
        width: 100%;
        padding: 0.5em 0 0.5em 0;

        button {
          margin: 0 0.5em;
        }
      }
    }

    mat-cell {
      min-height: unset;
      width: 100% !important;
      justify-content: space-between;
      padding-left: 0 !important;

      &:not(:last-child) {
        border-bottom: 1px solid #6a6a6a;
      }
    }

    mat-cell.hide {
      display: none;
    }

    // Footer
    mat-footer-row {
      display: none;
    }
  }
}

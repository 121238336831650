@use "@angular/material" as mat;
@include mat.core();
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "./assets/estilos/light-theme.scss";
@import "./assets/fonts/fonts.css";

.bg-warn {
  background-color: var(--input-color);
  color: var(--warn-color);
}

.dark-theme {
  @import "./assets/estilos/dark-theme.scss";
  @include mat.all-component-themes($dark-theme);
  background-image: url("/assets/images/fondos/fondoOscuro3.jpg");
  color: white;

  // Colores
  --primary-color: #{$dark-primary-color};
  --accent-color: #{$dark-accent-color};
  --warn-color: #{$dark-warn-color};
  --primary-color-contrast: #{$dark-primary-color-contrast};
  --accent-color-contrast: #{$dark-accent-color-contrast};
  --warn-color-contrast: #{$dark-warn-color-contrast};
  --bg-color-solid: #{$dark-bg-color-solid};
  --bg-color: #{$dark-bg-color};
  --bg-color-85: #{$dark-bg-color-85};
  --fg-color: #{$dark-fg-color};
  --negro-transparente: #{$negro-transparente};
  --blanco-transparente: #{$blanco-transparente};
  --input-color: #{$dark-input-color};

  .bg-warn {
    background-color: var(--input-color);
    color: white;
  }
}

// @include mat.core();

@import "./app/auxiliares/mat-table-responsive/mat-table-responsive.scss";
@import "./assets/estilos/ng-select.scss";
@import "./assets/estilos/style.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "monserrat-regular", "arial", sans-serif;
  color: black;
  background-image: url("/assets/images/fondos/fondoClaro1.jpg");
  background-size: cover;
  background-position: center center;
}

mat-form-field.no-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

mat-form-field {
  width: 100%;
}

.dialog-no-padding mat-dialog-container {
  padding: 0;
}

.mat-menu-panel {
  max-width: 600px !important;
}

.mat-mdc-menu-panel {
  max-width: 600px !important;
}

.highcharts-credits {
  display: none;
}

.mat-datepicker-content {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  background-color: #4a4a4a;
  color: white;
}

.mat-calendar-body-selected {
  background-color: #69f0ae;
  color: black;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

// Maps
* {
  google-map > div > div > div > div:last-child {
    display: none;
  }

  a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }
  a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }
}
// Maps

// Tablas
* {
  mat-cell {
    padding: 3px;
    span {
      overflow: hidden;
    }
  }

  .no-info {
    font-style: italic;
    color: gray;
    overflow: visible;
  }
}
// Tablas

// SnackBar
* {
  .red-snackbar {
    background-color: #f44336;
    color: white;
  }
  .green-snackbar {
    background-color: #4caf50;
    color: white;
  }
  .yellow-snackbar {
    background-color: #ffeb3b;
    color: black;
  }
}
// SnackBar

.filtro-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  @media screen and (min-width: 600px) {
    gap: 0.5em;
  }

  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 30px 30px 0 0;
  align-items: center;

  mat-form-field > div {
    border-radius: 0 30px 30px 0;
  }
}

.align-end {
  justify-content: flex-end;
}

.flex-row {
  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
}

.transparente .highcharts-background {
  fill: transparent;
  stroke: transparent;
}

.redondo-abajo .mat-mdc-dialog-surface {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.redondo-abajo-izquierda .mat-mdc-dialog-surface {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 0 !important;
}

.bg-transparente {
  background-color: transparent !important;
}

.cdk-overlay-pane:not(.mat-mdc-tooltip-panel) {
  overflow: hidden !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.form-transparente {
  .mat-mdc-text-field-wrapper {
    background: transparent !important;
  }
}

:root .gm-style-iw-c,
:root .gm-style-iw-d {
  padding: 0;
  margin: 0;
  overflow: hidden !important;
  font: inherit;
  border-radius: 20px;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

.mobile-align-right {
  @media screen and (max-width: 600px) {
    text-align: right;
  }
}

.d-none {
  display: none !important;
}

// Un contenedor rancio de los infowindow
.gm-style .gm-style-iw-c {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
}

// En esto esta la cruz de cerrar el infoWindow
.gm-style-iw-chr {
  translate: 0 47px;
}

// Color de las opciones selecionadas en los selects
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
  color: var(--fg-color) !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: var(--accent-color) !important;
}

.nowrap {
  white-space: nowrap;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column-flex-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.mdc-evolution-chip-set__chips {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

@use "sass:map";
@use "@angular/material" as mat;
@import "./assets/fonts/fonts.css";
@include mat.core();

$light-primary-palette-colors: (
  50: #e3e7ed,
  100: #bac2d1,
  200: #8c9ab2,
  300: #5d7193,
  400: #3b527c,
  500: #183465,
  600: #152f5d,
  700: #112753,
  800: #0e2149,
  900: #081537,
  A100: #708eff,
  A200: #3d66ff,
  A400: #0a3eff,
  A700: #0032f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$light-accent-palette-colors: (
  50: #e1f5f9,
  100: #b4e6f0,
  200: #83d6e6,
  300: #51c5db,
  400: #2bb8d4,
  500: #06accc,
  600: #05a5c7,
  700: #049bc0,
  800: #0392b9,
  900: #0282ad,
  A100: #d7f3ff,
  A200: #a4e5ff,
  A400: #71d6ff,
  A700: #58cfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$light-warn-palette-colors: (
  50: #fee0e2,
  100: #fdb3b6,
  200: #fb8085,
  300: #f94d54,
  400: #f82630,
  500: #f7000b,
  600: #f6000a,
  700: #f50008,
  800: #f30006,
  900: #f10003,
  A100: #ffffff,
  A200: #ffe5e5,
  A400: #ffb2b2,
  A700: #ff9898,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$light-my-primary: mat.define-palette($light-primary-palette-colors);
$light-my-accent: mat.define-palette($light-accent-palette-colors);
$light-my-warn: mat.define-palette($light-warn-palette-colors);

$light-primary-color: mat.get-color-from-palette($light-my-primary, 500);
$light-primary-color-contrast: mat.get-color-from-palette(
  $light-my-primary,
  "default-contrast"
);
$light-accent-color: mat.get-color-from-palette($light-my-accent, 500);
$light-accent-color-contrast: mat.get-color-from-palette(
  $light-my-accent,
  "default-contrast"
);

$light-warn-color: mat.get-color-from-palette($light-my-warn, 500);
$light-warn-color-contrast: mat.get-color-from-palette(
  $light-my-warn,
  "default-contrast"
);

$light-bg-color-solid: white;
$light-bg-color: rgba($light-bg-color-solid, 0.95);
$light-bg-color-85: rgba($light-bg-color-solid, 0.85);
$light-fg-color: black;

$negro-transparente: #0000002d;
$blanco-transparente: #ffffff80;
$light-input-color: #0000000a;

:root {
  --primary-color: #{$light-primary-color};
  --accent-color: #{$light-accent-color};
  --warn-color: #{$light-warn-color};
  --primary-color-contrast: #{$light-primary-color-contrast};
  --accent-color-contrast: #{$light-accent-color-contrast};
  --warn-color-contrast: #{$light-warn-color-contrast};
  --bg-color-solid: #{$light-bg-color-solid};
  --bg-color: #{$light-bg-color};
  --bg-color-85: #{$light-bg-color-85};
  --fg-color: #{$light-fg-color};
  --negro-transparente: #{$negro-transparente};
  --blanco-transparente: #{$blanco-transparente};
  --input-color: #{$light-input-color};
  // Colores
  // --azul: #{$azul};
  // --celeste: #{$celeste};
  // --rojo: #{$rojo};
  // --verde: #{$verde};
  // --amarillo: #{$amarillo};
  // --gris: #{$gris};
  // --naranja: #{$naranja};
  // --rosa: #{$rosa};
}

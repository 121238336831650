@use "sass:map";
@use "@angular/material" as mat;
@import "./dark-colores.scss";
@include mat.core();

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-my-primary,
      accent: $dark-my-accent,
      warn: $dark-my-warn,
    ),
    is-dark: true,
    typography:
      mat.define-typography-config(
        $font-family: "monserrat-regular",
      ),
    density: 0,
  )
);

@function my-background($color) {
  @return (
    status-bar: rgba($color, 100),
    app-bar: rgba($color, 100),
    background: transparent,
    hover: rgba(255, 255, 255, 0.04),
    card: rgba($color, 100),
    dialog: rgba($color, 100),
    disabled-button: rgba(0, 0, 0, 0.3019607843),
    raised-button: #424242,
    focused-button: rgba(255, 255, 255, 0.12),
    selected-button: #212121,
    selected-disabled-button: #424242,
    disabled-button-toggle: black,
    unselected-chip: #616161,
    disabled-list-option: rgba(255, 255, 255, 0.12),
    tooltip: $color,
    table: rgba($color, 100)
  );
}

@function my-foreground($color) {
  @return (
    base: rgba($color, 0.85),
    divider: rgba(#ffffff, 0.12),
    dividers: rgba(#ffffff, 0.12),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 0.87),
    slider-min: rgba($color, 0.38),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

$dark-theme: map.set(
  $dark-theme,
  color,
  background,
  my-background($dark-bg-color)
);
$dark-theme: map.set(
  $dark-theme,
  color,
  foreground,
  my-foreground($dark-fg-color)
);

// @debug $theme;

@include mat.datepicker-date-range-colors(hotpink, teal, yellow, purple);

@include mat.all-component-themes($dark-theme);

.dense-3 {
  @include mat.all-component-densities(-3);
}

@import "./scrollbar.scss";

.border-box {
  border-top: 4px solid var(--accent-color);
  border-radius: 6px;
}

.bg-primary {
  background-color: var(--primary-color);
  color: var(--primary-color-contrast);
}

.bg-primary2 {
  background-color: var(--bg-color-solid);
  color: var(--primary-color-contrast);
}

.bg-accent {
  background-color: var(--accent-color);
  color: var(--accent-color-contrast);
}

.bg-warn2 {
  background-color: var(--warn-color);
  color: var(--warn-color-contrast);
}

.active:focus::before {
  background: transparent !important;
}
.active:focus,
.active {
  border-right: 5px solid var(--accent-color);
  span {
    color: var(--accent-color) !important;
  }
  svg {
    fill: var(--accent-color) !important;
  }
}

a:not(.active) {
  .icon-menu {
    svg {
      fill: var(--fg-color) !important;
    }
  }
}

.card-container {
  background: var(--bg-color-solid);
  border-top: 7px solid var(--accent-color);
  margin: 0 1em 1em 1em;
  padding: 0;
  width: 390px;
  border-bottom-left-radius: 30px;
}

.titulo {
  width: 100%;
  background-color: var(--accent-color);
  color: var(--accent-color-contrast);
}

.referencia {
  background-color: var(--bg-color-solid);
  margin: 0.5em;
  padding: 0.3em 0.3em 0.3em 1em;
  border-radius: 30px;
}

// TABLAS

.boton-filtro {
  height: 60px !important;
  background-color: var(--bg-color-solid) !important;
  border-radius: 0 30px 30px 0 !important;
}

.boton-filtro-redondo {
  height: 60px !important;
  background-color: var(--bg-color-solid) !important;
  border-radius: 30px !important;
  padding-left: 1em !important;
}

.mat-mdc-tab {
  background-color: var(--bg-color-85) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--accent-color) !important;
}

.fondo {
  background-color: var(--bg-color-solid);
}

.divider-card {
  --mat-divider-width: 3px !important;
  margin: 1em 0 !important;
  border-top-color: var(--input-color);
}

.divider-navigator {
  --mat-divider-width: 1px;
  border-top-color: gray;
}

.columnas-select {
  color: var(--fg-color) !important;
}

.checkbox-text {
  color: var(--fg-color) !important;
}

.form-container {
  width: 450px;
  max-width: 90%;
  height: 425px;
  margin: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border-radius: 30px;
  background-color: var(--bg-color-85) !important;
}

.login-button {
  background-color: var(--input-color);
  width: 90% !important;
  height: 50px !important;
  padding: 0.8em !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--fg-color);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232300;
}

.label-error {
  background-color: var(--warn-color);
  color: var(--warn-color-contrast);
}

.form-field-primary {
  div:first-child {
    background-color: var(--bg-color-85) !important;
  }
}

.dialog {
  border-top: var(--accent-color) solid 12px;
}

mat-dialog-content {
  color: var(--fg-color) !important;
}

.mat-mdc-card-header {
  border-top: var(--accent-color) solid 12px;
}

.fecha-notificacion {
  color: var(--accent-color) !important;
}

.mat-mdc-checkbox label {
  color: var(--fg-color) !important;
}

.fg-color {
  color: var(--fg-color) !important;
  svg {
    fill: var(--fg-color) !important;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label--float-above {
  color: var(--fg-color) !important;
}

//// FONDOS
.mat-drawer {
  background-color: var(--bg-color-85) !important;
}
.mat-toolbar {
  background-color: var(--bg-color-85) !important;
}

.fondo-85 {
  .mat-mdc-button {
    background-color: var(--bg-color-85) !important;
  }
  background-color: var(--bg-color-85) !important;
}

.shadow {
  box-shadow: 0 5px 15px 3px #00000078 !important;
  border-top: 10px solid var(--accent-color);
  overflow: visible !important;
}

.gm-style .gm-style-iw-tc::after {
  background: var(--bg-color-solid);
}

:root .gm-ui-hover-effect > span {
  background-color: var(--fg-color) !important;
}

.boton-guardar {
  color: var(--accent-color-contrast) !important;
}

/////// MATERIAL SOS CACA ///////
/// TOOLTIP RANCIO DEL ORTO ///

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--bg-color-solid) !important;
  color: var(--fg-color) !important;
  box-shadow: 0 0 7px 5px rgb(0 0 0 / 13%);
}

:root .mdc-checkbox__background {
  // border-color: var(--fg-color) !important;
  // background-color: transparent !important;

  svg {
    color: white !important;
  }
}
